<template>
  <div id="center">
    <NavBar :message="status"></NavBar>
    <header>
       <p class="header_p1">阅图智能</p>
      <p class="header_p2">阅见世界 图观未来</p>
    </header>
    <main>
<p class="p1">智能软件</p>
      <p class="p2"> 阅图智能创立于2020年，通过AI技术为全球港口提供全面智慧化解决方案；不断提高港口生产效率和安全管理水平。</p>
      <div class="main_product1">
        <div class="product_content">
             <p class="con_p1">智能安全管控平台</p>
             <p class="con_p2">方便快捷，排查港口任何一个角落的安全隐患。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img1.png" alt="">
             </div>
        </div>
      </div>
      <div class="main_product2">
        <div class="product_content">
             <p class="con_p1">智能理货系统</p>
             <p class="con_p2">进场道口、岸桥、轨道吊、轮胎吊等区域和设备使用智能理货系统，秒级获取箱号、箱型、封签、箱损、车牌号、集卡号等信息，识别率高达99%。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img2.jpg" alt="">
             </div>
        </div>
      </div>
      <div class="main_product3">
        <div class="product_content">
             <p class="con_p1">多维数据分析</p>
             <p class="con_p2">系统汇聚各子系统以及外部接入的数据，建立数据分析模型，自动生成可视化图形报表，真正做到：实时数据可看、历史数据分析、未来数据预测。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img3.png" alt="">
             </div>
        </div>
      </div>
       <div class="main_product4">
        <div class="product_content">
             <p class="con_p1">港口设备远控自动化作业路人员安全防护应用</p>
             <p class="con_p2">利用远控相机在作业时实时对人员状态和吊具状态进行监控，识别吊具可能对作业人员造成危险伤害的场景，向操作人员发出提示，避免作业事故的发生。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img4.png" alt="">
             </div>
        </div>
      </div>
      <div class="main_product4">
        <div class="product_content">
             <p class="con_p1">锁销检测/箱底异物</p>
             <p class="con_p2">对作业拆装锁状态进行监测，发现异常情况，及时联动作业控制信号，避免发生集装箱倒塌事故。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img7.png" alt="">
             </div>
        </div>
      </div>
      <div class="main_product4">
        <div class="product_content">
             <p class="con_p1">大车防撞检测</p>
             <p class="con_p2">融合视觉、激光雷达等多维数据，在大车行走模式下，全方位保证安全。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img5.png" alt="">
             </div>
        </div>
      </div>
       <div class="main_product4">
        <div class="product_content">
             <p class="con_p1">人员下车检测</p>
             <p class="con_p2">对场区内自主定义的功能区域，进行安全规范识别，全时段监测未佩戴安全帽、违规下车等行为。</p>
             <div class="con_img">
               <img src="../../assets/images/center_img6.png" alt="">
             </div>
        </div>
      </div>
    </main>
    <footer>
      <NavFooter></NavFooter>
    </footer>
    <Scroll></Scroll>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/navBar.vue";
import NavFooter from '../../components/Footer/footer.vue'
import Scroll from '../../components/scroll/scroll.vue'

export default {
  data() {
    return {
      status: "",
    };
  },
  components: {
    NavBar,NavFooter,Scroll
  },
  mounted() {
    this.status = 2;
  },
};
</script>

<style lang='scss' scoped>
@import "./center.scss";
</style>